export default function Checkbox({
  value, setValue, name = 'checkbox', label = '', description = '',
}) {
  const set = (v) => {
    console.log(label, v);

    setValue(v);
  };

  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          id={`data-input-${name}`}
          aria-describedby="comments-description"
          name={name}
          type="checkbox"
          onChange={(e) => set(e.target.checked + 0)}
          defaultChecked={value === true || value === 1 || value === '1' || value === 'true'}
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
      </div>
      <div className="ml-3" style={{ fontSize: '.95rem' }}>
        <label htmlFor={`data-input-${name}`} className="font-semimedium text-gray-700">
          {label}
        </label>
        {description && (
          <p id={`data-desc-${name}`} className="text-gray-500">
            {description}
          </p>
        )}
      </div>
    </div>
  );
}
